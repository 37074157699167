import { NextPage } from "next";
import React from "react";

import { Box, Grid, Typography } from "@mui/material";

const NotFound: NextPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box style={{ height: 200 }} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" color="textSecondary">
          {"指定したページは存在しません"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFound;
